/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { WhiteLogo } from "../../../../../constants/ImageConstants";
import { getNavigateUrl } from "../../../../../utils/customHelper";
import {
  CityListAction,
  // VehicleAction
} from "../../../../../store/action";
import "./index.scss";
import Tawkto from "../../../../../components/common/Tawkto";
import CallBackPopup from "../../../../../components/common/CallBackPopup";
import { REMOVE_VEHICLE } from "../../../../../store/types";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";

const Footer = (props) => {
  const { updateReducer } = props;
  const navigate = useNavigate();
  const selectedCityName = props.cityListReducer.selectedCity;
  // const setMajorBrand = (path) => {
  //   updateReducer({ type: REMOVE_VEHICLE });
  //   navigate(getNavigateUrl(path));
  // };
  const setMajorBrand1 = (path) => {
    updateReducer({ type: REMOVE_VEHICLE });
  };
  return (
    <Row>
      <div className="main-footer pt-0">
        <div
          className="auto-container"
          style={{ paddingTop: props?.paddingTop ? "80px" : "0px" }}
        >
          <div className="widgets-section pb-0">
            <div className="row clearfix">
              <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget address-widget">
                  <h5>Company</h5>
                  <ul className="list-info">
                    <li>
                      <Link to={"/aboutus"}>About</Link>
                    </li>
                    <li>
                      <Link to={"/contactus"}>Contact us</Link>
                    </li>
                    <li>
                      <a
                        href="https://vehiclecare.in/blaze"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <Link to={"/career"}>Career</Link>
                    </li>
                    <li>
                      <Link to={"/media"}>Media</Link>
                    </li>
                    <li>
                      <Link to={"/faq"}>Faq</Link>
                    </li>
                    <li>
                      <Link to={"/lubricants"}>Lubricants</Link>
                    </li>
                    <li>
                      <Link to={"/reviews"}>Review</Link>
                    </li>

                    <li>
                      <Link to={"/termsncondition"}>Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to={"/privacypolicy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget address-widget">
                  <h5>Top Pages</h5>
                  <ul className="list-info">
                    <li>
                      <a href={"https://vehiclecare.in/gurgaon/car-service"}>
                        Car Service In Gurgaon
                      </a>
                    </li>
                    <li>
                      <a href={"https://vehiclecare.in/mumbai/car-service"}>
                        Car Service In Mumbai
                      </a>
                    </li>

                    <li>
                      <a href={"https://vehiclecare.in/delhi/car-service"}>
                        Car Service In Delhi
                      </a>
                    </li>
                    <li>
                      <a href={"https://vehiclecare.in/ghaziabad/car-service"}>
                        Car Service In Ghaziabad
                      </a>
                    </li>
                    <li>
                      <a href={"https://vehiclecare.in/pune/car-service"}>
                        Car Service In Pune
                      </a>
                    </li>
                    <li>
                      <a href={"https://vehiclecare.in/bengaluru/car-service"}>
                        Car Service In Bengaluru
                      </a>
                    </li>
                    <li>
                      <a href={"https://vehiclecare.in/ahmedabad/car-service"}>
                        Car Service In Ahmedabad
                      </a>
                    </li>

                    <li>
                      <a href={"https://vehiclecare.in/chandigarh/car-service"}>
                        Car Service In Chandigarh
                      </a>
                    </li>
                    <li>
                      <a href={"https://vehiclecare.in/faridabad/car-service"}>
                        Car Service In Faridabad
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget address-widget">
                  <h5>Popular Brands</h5>

                  <ul className="list-info">
                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/maruti-suzuki-baleno-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Maruti Suzuki
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/tata-altroz-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Tata
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/honda-city-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Honda
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/toyota-innova-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Toyota
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/hyundai-creta-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Hyundai
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/kia-seltos-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Kia
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/mg-hector-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        MG
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/audi-q3-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Audi
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`/${selectedCityName
                          .replace(/ /g, "-")
                          .toLowerCase()}/services/periodic-car-services/mahindra-xuv-700-petrol`}
                        onClick={() => setMajorBrand1()}
                        className="getPointer"
                      >
                        Mahindra
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget address-widget">
                  <h5>Services Near By</h5>
                  <ul className="list-info">
                    <li>
                      <Link to={"/car-workshop-near-me"}>
                        Car Workshop Near Me
                      </Link>
                    </li>
                    <li>
                      <Link to={"/car-garage-near-me"}>Car Garage Near Me</Link>
                    </li>
                    <li>
                      <Link to={"/car-mechanic-near-me"}>
                        Car Mechanic Near Me
                      </Link>
                    </li>
                    <li>
                      <Link to={"/car-wash-near-me"}>Car Wash Near Me</Link>
                    </li>
                    <li>
                      <Link to={"/car-ac-repair-near-me"}>
                        Car AC Repair Near Me
                      </Link>
                    </li>
                    <li>
                      <Link to={"/car-repair-near-me"}>Car Repair Near Me</Link>
                    </li>
                    <li>
                      <Link to={"/car-service-near-me"}>
                        Car Service Near Me
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={"/car-scrapping"}>Partner Workshop</Link>
                    </li> */}
                    <li>
                      <Link to={"/car-scrapping"}>Car Scrapping</Link>
                    </li>
                    <li>
                      <a href="https://vehiclecare.in/stores/workshop-locator.php">
                        Partner Workshop
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd footer */}
      <div className="main-footer pt-0" style={{ background: "black" }}>
        <div className="auto-container">
          <div className="widgets-section pb-0">
            <div className="row clearfix">
              <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                <div className="footer-widget logo-widget">
                  <div className="logo">
                    <Link to={getNavigateUrl("/")}>
                      <LazyLoad>
                        <img
                          src={WhiteLogo}
                          className="w-50"
                          alt="VehicleCare Logo"
                        />
                      </LazyLoad>
                    </Link>
                  </div>
                  <div className="text">
                    VehicleCare offers AI-enabled premium quality car services
                    at affordable rates. We offer budget-friendly and
                    transparent pricing with real-time updates that solidify
                    your trust and confidence in car care. Our high-tech smart
                    automotive solutions efficiently cater to all kinds of car
                    needs.
                  </div>
                  <ul className="social-box">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/vehiclecare.in"
                        className="fa fa-facebook-f"
                        rel="nofollow noreferrer"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/vehiclecare"
                        className="fa fa-linkedin"
                        rel="nofollow noreferrer"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/vehiclecare1"
                        className="fa fa-twitter"
                        rel="nofollow noreferrer"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/vehiclecare.in"
                        className="fa fa-instagram"
                        rel="nofollow noreferrer"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@VehicleCare"
                        className="fa fa-youtube"
                        rel="nofollow noreferrer"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://api.whatsapp.com/send/?phone=918859793310&text=I%20Need%20Help%20In&type=phone_number&app_absent=0"
                        className="fa fa-whatsapp"
                        rel="nofollow noreferrer"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget address-widget">
                  {/* <h5>Address</h5> */}
                  <div className="widget-content">
                    <div className="text">
                      MiQB Executive Coworking Space
                      <span>
                        {" "}
                        Plot 23, Sector 18 Maruti Industrial Development Area,
                        Gurugram, Haryana 122015
                      </span>
                    </div>
                    <ul className="list-info">
                      <li>
                        Email:{" "}
                        <a href="mailto:support@vehiclecare.in">
                          support@vehiclecare.in
                        </a>
                      </li>
                      <li>
                        Phone: <a href="tel:+91-9911-020-209">9911-020-209</a>,
                        <a href="tel:+91-8010-90-0700" className="px-2">
                          8010-90-0700
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget links-widget">
                  {/* <h5>Pages</h5> */}
                  {/* <LazyLoad>
                  <a href="https://play.google.com/store/apps/details?id=com.app.arvindVehicleCare" rel="nofollow">
                    <img src="/static/media/Android QR.11f575c8d2b6a3ec855e.png" alt="VehicleCare Android APP" loading=" lazy" className="w-75" />
                  </a>
                  </LazyLoad> */}
                </div>
              </div>
            </div>
          </div>

          <div className={`footer-bottom pb-4 ${props?.bottomTabs && 'mb-5'}`}>
            <div className="inner-container">
              <div className="row clearfix">
                <div className="column col-lg-12 col-md-12 col-sm-12">
                  <div className="copyright">
                    Copyright &copy; 2016-{moment().format("YYYY")} Houseneed
                    Doorstep Services Private Limited. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* get call back popup */}
      <CallBackPopup />

      {/* <Tawkto /> */}
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getCityList: (params) => CityListAction.getCityList(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
