import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const WhyChoose = (props) => {
  const data = props.vehicleData;
  const { vehicleData, cityData } = props;
  const { selectedCity } = cityData;
  const urlParam = useParams();
  const cgID = urlParam.id;
  const { brand, model, variant } = vehicleData;
  const getCustomHelmetDetails = (cgID) => {
    let returnVal = [];
    if (variant === null || variant === undefined) return returnVal;
    switch (cgID) {
      case "periodic-car-services":
        returnVal = [
          {
            MainTitle: "",
            title1: `Why Periodic Service is Essential:`,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Ensures optimal performance and longevity.
                  </li>
                  <li className="list-group-item">
                    Detects potential issues early, avoiding costly repairs.
                  </li>
                  <li className="list-group-item">
                    Key components like the engine, brake pads, and tyres are
                    inspected.
                  </li>
                  <li className="list-group-item">
                    Regular checks on lights, brakes, and tyres ensure road
                    safety.
                  </li>
                  <li className="list-group-item">
                    Prevents unexpected breakdowns.
                  </li>
                  <li className="list-group-item">
                    Boosts resale value with a well-maintained car.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `Our Service Packages:`,
            description2: (
              <>
                <font color="#ed1c24">
                  <b>Basic Car Service -</b>{" "}
                </font>
                Essential services to keep your {model?.name} running smoothly.
                <br />
                <font color="#ed1c24">
                  <b>Standard Car Service -</b>{" "}
                </font>
                Popular choice with extra services on top of the basic.
                <br />
                <font color="#ed1c24">
                  <b>Comprehensive Car Service -</b>{" "}
                </font>
                Our premium package for full inspection and safety.
                <br />
              </>
            ),
          },
          {
            title3: `Why VehicleCare?`,
            description3: (
              <>
                <font color="#ed1c24"></font>Skilled professionals, genuine
                parts, and top-quality service. Satisfaction is guaranteed, with
                warranties and high-tech tools for all services.From AC gas
                recharging to wheel balancing, we deliver happiness with care.
              </>
            ),
          },
        ];
        break;
      case "denting-painting":
        returnVal = [
          {
            MainTitle: "",
            title1: `Why Fix Dents?`,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Dents lower your car’s resale value.
                  </li>
                  <li className="list-group-item">
                    Leaving dents leads to rust, raising repair costs.
                  </li>
                  <li className="list-group-item">
                    Restores your car's appearance and maintains its visual
                    appeal.
                  </li>
                  <li className="list-group-item">
                    Protects the structural integrity of the car.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `Why VehicleCare?`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Denting-painting services for all car models in{" "}
                    {selectedCity}.
                  </li>
                  <li className="list-group-item">
                    Genuine, high-grade paints with advanced tools.
                  </li>
                  <li className="list-group-item">
                    Services include - Localised per-panel repairs, Full body
                    denting and painting, Custom and fibre/plastic dent removal,
                    100% colour match for a showroom-like finish.
                  </li>
                  <li className="list-group-item">
                    Paint warranties at every workshop.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title3: `The VehicleCare Promise:`,
            description3: (
              <>
                Attention to detail with manufacturer-approved paints.
                Guaranteed quality and durability with a flawless finish.
              </>
            ),
          },
        ];
        break;
      case "car-ac-services":
        returnVal = [
          {
            title1: (
              <>
                Choosing VehicleCare Car AC Services for {brand?.name}{" "}
                {model?.name} in {selectedCity} can help maintain good health of
                your Car AC and therefore increases its life. It will ensure:
              </>
            ),
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    A comfortable driving experience{" "}
                  </li>
                  <li className="list-group-item">
                    Reduces concentration of allergens, pollutants and
                    contaminants.
                  </li>
                  <li className="list-group-item">
                    Helps preserve the interior components of the car, including
                    the dashboard and upholstery, by preventing damage caused by
                    prolonged exposure to high temperatures and sunlight
                  </li>
                  <li className="list-group-item">
                    During colder weather, the car AC system is essential for
                    defogging and demisting windows. This enhances visibility,
                    reducing the risk of accidents caused by obscured windows.
                  </li>
                  <li className="list-group-item">
                    Regular maintenance helps extend the life of AC components,
                    reducing the likelihood of major breakdowns.
                  </li>
                  <li className="list-group-item">
                    A properly functioning AC system is more energy-efficient,
                    contributing to overall fuel efficiency.
                  </li>
                  <li className="list-group-item">
                    Regular checks and maintenance help identify and address
                    refrigerant leaks promptly.
                  </li>
                  <li className="list-group-item">
                    Routine checkup and maintenance and timely lubrication
                    reduces the risk of premature compressor failure, therefore
                    extending its life.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `What VehicleCare Car AC Service offers:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Comprehensive Car AC service for optimal functioning of your{" "}
                    {brand?.name} {model?.name} Car.
                  </li>
                  <li className="list-group-item">
                    At VehicleCare we conduct detailed inspection of refrigerant
                    level, pressure testing,high pressure and low pressure
                    leakage identification, cabin air filter checking.
                  </li>
                  <li className="list-group-item">
                    At VehicleCare, we do car AC condenser cleaning, car AC
                    cooling coil and evaporator cleaning, AC blower and vent
                    cleaning, car AC filter cleaning with high pressure jet
                    washer and other sophisticated equipment. We use best
                    quality specialised agents and car safe chemical reagents
                    and conduct all cleaning activities based on manufacturer
                    recommendations.
                  </li>
                  <li className="list-group-item">
                    We provide Car AC gas recharge or replacement in which we
                    use an environmentally friendly refrigerant and AC gas
                    charging machine.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title3: <>How do we do it?</>,
            description3: (
              <>
                We employ the best and most sophisticated and automated industry
                level equipment to conduct inspection and perform servicing or
                replacement. Our team of professionals are trained to
                effectively use state of the art apparatus for all kinds of Car
                AC repair and replacements. From automatic car AC recharging
                tools to AC gas leak detection tools and other specialised
                equipment, VehicleCare uses the best and most advanced machinery
                to deliver the best Car AC service for your {brand?.name}{" "}
                {model?.name} in {selectedCity}.
              </>
            ),
          },
          {
            title4: (
              <>
                Warranty On {brand?.name} {model?.name} Car AC services
              </>
            ),
            description4: (
              <>
                With VehicleCare get assured warranty on your AC Car service in
                all our workshops in {selectedCity}.{" "}
              </>
            ),
          },
        ];
        break;
      case "mechanical-repairs":
        returnVal = [
          {
            MainTitle: "",
            title1: `Key Features:`,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">Skilled professionals.</li>
                  <li className="list-group-item">Best prices with rewards.</li>
                  <li className="list-group-item">
                    100% genuine OEM/OES spares.
                  </li>
                  <li className="list-group-item">
                    State-of-the-art diagnostics.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `Convenient & Affordable:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Easily book through our app or website.
                  </li>
                  <li className="list-group-item">
                    Pick-up and drop service for all repairs.
                  </li>
                  <li className="list-group-item">
                    Dedicated representative to assist throughout.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title3: `Why VehicleCare?`,
            description3: (
              <>
                <font color="#ed1c24">
                  <b>{brand.name} Expertise: </b>{" "}
                </font>
                Specialized care for your {model?.name} with expert technicians.
                <br />
                <font color="#ed1c24">
                  <b>SSkilled Technicians: </b>{" "}
                </font>
                Certified, precise repairs for {brand.name} models.
                <br />
                <font color="#ed1c24">
                  <b>Transparent Pricing: </b>{" "}
                </font>
                Clear estimates are upfront, so there are no surprises.
                <br />
                <font color="#ed1c24">
                  <b>Timely Service: </b>{" "}
                </font>
                Quick repairs to minimise downtime.
                <br />
                <font color="#ed1c24">
                  <b>Warranty Assurance: </b>{" "}
                </font>
                Warranties on all parts and repairs.
                <br />
                <font color="#ed1c24">
                  <b>Genuine Spare Parts: </b>{" "}
                </font>
                Only top-quality parts are used.
                <br />
                <font color="#ed1c24">
                  <b>100% Satisfaction: </b>{" "}
                </font>
                We guarantee your satisfaction with every repair.
              </>
            ),
          },
        ];
        break;
      case "wheel-care":
        returnVal = [
          {
            title1: <>VehicleCare Tyre Service </>,
            description1: (
              <>
                Wheels are crucial for car health as they directly impact
                safety, handling, and overall performance of the vehicle. They
                are the only point of contact on the road, so their good
                condition becomes extremely crucial for road safety. Properly
                maintained tires ensure optimal traction, stability, and
                braking. Regular inspection and adequate tire pressure prevent
                accidents, enhance fuel efficiency, and contribute to the
                longevity of the vehicle's suspension and steering components.
                <br />
                <br />
                Regular wheel checkups are vital for a safe drive as they ensure
                proper tire pressure, alignment, and tread depth.
                Well-maintained wheels enhance vehicle stability, steering
                control, and braking efficiency, reducing the risk of accidents.
                Timely checks prevent issues, promoting a safe driving
                experience and extending the life of the tires.
              </>
            ),
          },
          {
            title2: `VehicleCare is the ultimate Wheel Health Manager of your Car`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    VehicleCare offers a comprehensive range of wheel services
                    tailored for optimal performance. This includes precise
                    laser assisted wheel balancing to eliminate vibrations,
                    wheel alignment for proper tire positioning, nitrogen
                    inflation, tire rotation to promote even tread wear, and
                    tire replacement using genuine parts for longevity and
                    safety.
                  </li>
                  <li className="list-group-item">
                    We also offer complementary tire fitting for every tire
                    purchased through VehicleCare Workshops.
                  </li>
                  <li className="list-group-item">
                    We offer tyres from leading brands like, MRF, Bridgestone,
                    Apollo etc.
                  </li>
                  <li className="list-group-item">
                    With state-of-the-art equipment, skilled technicians at
                    VehicleCare deliver these services with precision,
                    guaranteeing the health and safety of your vehicle's wheels,
                    alignment, and tires, especially beneficial for
                    high-performance vehicles like the {brand?.name}{" "}
                    {model?.name} Petrol.
                  </li>
                  <li className="list-group-item">
                    We employ only cutting edge technology wheel replacement,
                    wheel alignment/balancing, tyre rotation services.{" "}
                  </li>
                  <li className="list-group-item">
                    Wheel care services at VehicleCare are tailored to the
                    specific needs of your {brand?.name} {model?.name} Petrol,
                    ensuring that the service meets the highest standards set by{" "}
                    {brand?.name}.
                  </li>
                </ul>
              </>
            ),
          },
        ];
        break;
      case "car-cleaning":
        returnVal = [
          {
            title1: <>Car Cleaning Service by VehicleCare in {selectedCity} </>,
            description1: (
              <>
                Car cleaning services are crucial for maintaining your car's
                appearance, preserving its resale value, and preventing
                corrosion. Regular cleaning removes dirt, contaminants, and
                corrosive elements, protecting the paint and ensuring a
                well-kept, presentable vehicle. It also contributes to a
                healthier and more pleasant driving experience.
                <br />
                <br />
                Interior car care is a crucial aspect for a comfortable and
                healthy driving experience.Regular cleaning and maintenance
                prevent the buildup of dirt, allergens, and odors. It preserves
                the interior's aesthetic appeal, enhances air quality, and
                contributes to an enjoyable driving experience for both you and
                your passengers.
                <br />
                <br />
                VehicleCare's car cleaning service stands out for its meticulous
                attention to detail and use of high-quality, eco-friendly
                products. Specializing in luxury vehicles like the {
                  brand?.name
                }{" "}
                {model?.name}, the service goes beyond surface cleaning,
                addressing intricate interior components.
                <br />
                <br />
                The use of advanced equipment and techniques guarantees a
                showroom finish. Whether it's exterior detailing, interior care,
                or specialized cleaning for luxury cars, VehicleCare's service
                combines expertise, precision, and eco-conscious practices for
                an unparalleled car cleaning experience.
              </>
            ),
          },
          {
            title2: `What does VehicleCare offer in its Car Cleaning service in ${selectedCity}?`,
            description2: `VehicleCare comprehensive Car cleaning service is like a Spa for your Car. Whether you drive a Maruti, Hyundai or a luxury car like ${brand?.name}, we offer the finest car cleaning service in ${selectedCity} for your vehicle. `,
          },
          {
            title3: `Services included:`,
            description3: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Interior and exterior car cleaning
                  </li>
                  <li className="list-group-item">Car washing and waxing</li>
                  <li className="list-group-item">
                    Ceramic and teflon coating
                  </li>
                  <li className="list-group-item">Car polishing and buffing</li>
                  <li className="list-group-item">Anti-rust treatment</li>
                  <li className="list-group-item">
                    Tyre dressing and alloy cleaning
                  </li>
                  <li className="list-group-item">Car paint protection Film</li>
                  <li className="list-group-item">
                    Stain removal and odor elimination
                  </li>
                  <li className="list-group-item">vacuuming</li>
                  <li className="list-group-item">
                    Leather Upholstery Treatment
                  </li>
                  <li className="list-group-item">
                    Specialized cleaning and luxury car detailing
                  </li>
                  <li className="list-group-item">
                    Dry cleaning and interior car detailing
                  </li>
                </ul>
              </>
            ),
          },
        ];
        break;
      case "car-detailing-&-spa":
        returnVal = [
          {
            title1: <>VehicleCare Car Detailing </>,
            description1: (
              <>
                Car detailing is a thorough and meticulous cleaning,
                restoration, and protection process for both the interior and
                exterior of a vehicle. It goes beyond a standard car wash,
                addressing paint imperfections, interior surfaces, and other
                intricate details. Detailing enhances the vehicle's appearance,
                maintains its condition, and preserves its value.
                <br />
                <br />
                VehicleCare offers Car detailing and spa service for cars of all
                makes and models. Get your vehicle detailing and coating
                hassle-free with VehicleCare workshops in {selectedCity}. We use
                100% genuine industry grade and eco-friendly products for all
                our detailing services.
              </>
            ),
          },
          {
            title2: `Services offered under Car detailing:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">Teflon Coating</li>
                  <li className="list-group-item">Ceramic Coating</li>
                  <li className="list-group-item">
                    Anti-rust Underbody Coating
                  </li>
                  <li className="list-group-item">Paint Protection Film</li>
                  <li className="list-group-item">Silencer Coating</li>
                  <li className="list-group-item">
                    Waxing/Sealant Application
                  </li>
                  <li className="list-group-item">Seat Leather Treatment</li>
                </ul>
              </>
            ),
          },
          {
            description3: (
              <>
                VehicleCare prioritizes the use of eco-friendly and safe
                detailing products to ensure effective and responsible car care.
                Customers can inquire about the specific products used during
                the detailing process. All the VehicleCare workshops in{" "}
                {selectedCity} have state of the art equipment and specialized
                tools to deliver best-in-class detailing service to its
                customers. <br />
                <br />
                VehicleCare specializes in detailing of car brands like{" "}
                {brand?.name} etc. We also give warranty on our detailing
                services, however, the warranty period varies from service to
                service.
                <br />
                <br />
                VehicleCare's Car Spa typically includes a comprehensive package
                featuring exterior services like washing, waxing, and wheel
                cleaning. Interior services cover vacuuming, dashboard cleaning,
                and window polishing. Additional offerings may include headlight
                restoration, trim treatment, and optional fragrance application,
                providing a thorough rejuvenation for both the exterior and
                interior of your car.
              </>
            ),
          },
        ];
        break;
      case "custom-repairs":
        returnVal = [
          {
            title1: <>Experience the Best Custom Repair at VehicleCare</>,
            description1: (
              <>
                VehicleCare Custom repair services are solutions tailored to
                individual customer needs ranging from bodywork to specialized
                modifications. VehicleCare conducts detailed car scanning and
                runs other diagnostics and informs you about the possible
                repairs that would be required in the future. VehicleCare custom
                repair package includes clutch and suspension works and other
                important mechanical works that are crucial for your car’s
                health and safety. Under the VehicleCare custom repair work, a
                detailed mechanical diagnostic is run for your car which is
                crucial for your car’s health as it identifies underlying
                issues, preventing potential breakdown and ensuring optimal
                performance.
              </>
            ),
          },
          {
            title2: `Services included:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">Engine diagnostic</li>
                  <li className="list-group-item">Brake system diagnostic</li>
                  <li className="list-group-item">
                    Clutch and suspension work
                  </li>
                  <li className="list-group-item">
                    Electrical system Diagnostic
                  </li>
                </ul>
              </>
            ),
          },
          {
            description3: (
              <>
                VehicleCare's custom repairs stand out for their excellence due
                to a combination of factors. Skilled technicians with
                brand-specific expertise ensure precise diagnostics and repairs.
                The use of genuine parts, cutting-edge equipment, and a
                commitment to transparency in service and pricing contribute to
                the high-quality standards. VehicleCare prioritizes customer
                satisfaction.
              </>
            ),
          },
        ];
        break;

      default:
        break;
    }
    return returnVal;
  };
  const faqArr = getCustomHelmetDetails(cgID);
  return (
    <Row>
      <Col>
        <div className="auto-container" id="whychoose">
          <div className="upper-section">
            <div className="row clearfix d-flex justify-content-center">
              <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                <div className="inner-column">
                  <div className="sec-title mb-2">
                    <h2 className="text-left" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                      Why Choose VehicleCare for{" "}
                      {cgID
                        .toLowerCase()
                        .split("-")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}{" "}
                      {data.brand?.name} {data.model?.name}{" "}
                      {data.variant?.variant} Car Services in Gurgaon?
                    </h2>
                  </div>

                  {faqArr?.map((value, index) => (
                    <div eventKey={index}>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" mt-3 mb-4"
                      >
                        {value.MainTitle}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px", textAlign: "justify" }}
                          className="text-left text-capitalize"
                        >
                          {value.title1}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" mb-4"
                      >
                        {value.description1}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px", textAlign: "justify" }}
                          className="text-left text-capitalize"
                        >
                          {value.title2}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" text-left mt-2 mb-4"
                      >
                        {value.description2}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px" }}
                          className="text-left text-capitalize"
                        >
                          {value.title3}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" text-left mt-2 mb-4"
                      >
                        {value.description3}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px", textAlign: "justify" }}
                          className="text-left text-capitalize"
                        >
                          {value.title4}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" text-left mt-2 mb-4"
                      >
                        {value.description4}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer.cartList;
  stateObj.passingData = state.default.vehicleReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  stateObj.serviceList = state.default.serviceListReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.cityData = state.default.cityListReducer;
  return stateObj;
};
export default connect(mapStateToProps)(WhyChoose);
