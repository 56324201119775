import React, { useEffect, useState } from "react";
import { Row, Form } from "react-bootstrap";
import "./index.scss";
import { Appbg } from "../../../../constants/ImageConstants";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import OurApp from "../../../../components/common/ourApp";
import { connect } from "react-redux";
import { OrderAction, UserAction } from "../../../../store/action";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import PersonalProfile from "./personalProfile";
import MyGarage from "./myGarage";

import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import MobileView from "./mobileView";
import BottomTabs from "../../layout/NavigationBar/BottomsTabs";
import { BottomSheet } from "react-spring-bottom-sheet";
import { colors } from "@mui/material";
import { Trash3 } from "react-bootstrap-icons";
import VehicleSelector from "../../../../components/common/vehicleSelectorMobile";

const Profile = (props) => {
  const { userData, getUserProfile, updateUserVehicle } = props;
  const { full_profile, is_user_logged_in } = userData;
  const [bottomSheet, setBottomSheet] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  let profileData = full_profile?.user || full_profile;
  
  const makeDefaultVehicle = (id) => {
    props?.updateUserVehicle({
      task: "setUserVehiclesPreffered",
      id: id,
    });
  };
  const removeVehicle = (id) => {
    props?.updateUserVehicle({
      task: "removeUserVehicles",
      id: id,
    });
  };

  useEffect(() => {
    if (!is_user_logged_in) {
      document.getElementsByClassName("userprofile_icon")[0].click();
    }
    if (!profileData && is_user_logged_in) {
      getUserProfile({ task: "getUserCompleteProfile" });
    }
  }, [profileData, is_user_logged_in, getUserProfile]);

  useEffect(() => {
    if (full_profile) {
      setSelectedVehicle(
        profileData?.vehicles?.filter(
          (item) => item?.is_prefered === "1"
        )[0]
      );
    } else if (window.sessionStorage.getItem("vehicleDetails")){
      let parse = JSON.parse(window.sessionStorage.getItem("vehicleDetails"))
      const obj = {
        brand_name: parse?.brand?.name,
        model_name: parse?.model?.name,
        variant_name: parse?.variant?.variant,
        icon: parse?.model?.icon
      }
      setSelectedVehicle(obj)
    }
  }, [full_profile]);

  return (
    <>
      <Helmet>
        <title>We Are A Creating New Option In The VehicleCare Profile</title>
        <meta name="description" content="VehicleCare created a New Profile Option, You Can See All the Details in Our Profile And get the Benefits of This feature for You" />
      </Helmet>

      <Header isStatic={true}  selected_vehicle={selectedVehicle} setSheet={(boolean) => is_user_logged_in ? setBottomSheet(boolean) : setOpenModal(boolean)} />
      {!is_user_logged_in && openModal && <VehicleSelector navigate={false} hideBar={true} open={true} setModal={(bool) => setOpenModal(bool)} />} 
      <BottomSheet open={bottomSheet} onDismiss={() => setBottomSheet(false)}>
        <h4
          style={{
            color: colors.common.black,
            fontWeight: "bold",
            marginLeft: "15px",
          }}
        >
          My Garage
        </h4>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          {profileData?.vehicles?.map((item) => (
            <div
              style={{
                border: `1px solid ${
                  item?.is_prefered === "1" ? "red" : "lightgrey"
                }`,
                margin: "10px",
                padding: "20px 10px 20px 10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => makeDefaultVehicle(item?.id)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={item?.icon} width={90} />
                <div style={{ marginLeft: "10px" }}>
                  <a style={{ color: colors.common.black, fontWeight: "bold" }}>
                    {item?.model_name}
                  </a>
                  <br />
                  <a>{item?.variant_name}</a>
                </div>
              </div>
              <Trash3
                size={20}
                color={"red"}
                onClick={() => removeVehicle(item?.id)}
              />
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }} onClick={() => {
          let vehicleDetails = window.sessionStorage.getItem("vehicleDetails");
          if(!is_user_logged_in && !vehicleDetails){
            setOpenModal(true);
            setBottomSheet(false)
          }
        }}>
          <button
            style={{ width: "93%", borderRadius: "5px" }}
            class="theme-btn btn-style-four btn-sm px-2 py-2 my-2"
          >
            <span class="txt">Add New Car +</span>
          </button>
        </div>
      </BottomSheet>
      <Row>


   

      <MobileView />
      

      <div className="d-none ">
        <section className="faq-page-section pb-4" id="profile">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="accordian-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title px-3">
                    <h2 className="text-capitalize">Hi {profileData?.first_name} {profileData?.last_name}</h2>
                  </div>

                  <div className={`detail px-3 ${!is_user_logged_in && "eventOff"}`}>
                    <div className="d-flex justify-content-center col inner-box">
                      <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="personal_profile">
                          <Row>
                            
                            <div className="col-4">
                              <div className="sidebar-widget service-widget">
                                <Nav variant="pills" className="flex-column service-list">
                                  <Nav.Item>
                                    <Nav.Link eventKey="personal_profile">About</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="my_garage">My garage</Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item>
                                    <Nav.Link eventKey="second">Orders</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="insurance_claim">Insurance Claim History</Nav.Link>
                                  </Nav.Item> */}
                                  <Nav.Item>
                                    <Nav.Link eventKey="four">Wallet</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="five">Notification</Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item>
                                    <Nav.Link eventKey="six">Reviews</Nav.Link>
                                  </Nav.Item> */}
                                  <Nav.Item>
                                    <Nav.Link eventKey="seven">Settings</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="eight">Support</Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link eventKey="">Logout</Nav.Link>
                                  </Nav.Item>
                                </Nav>

                              </div>
                            </div>

                            <div className="col-8 px-0">
                              <Tab.Content>
                                <Tab.Pane eventKey="personal_profile">
                                  <PersonalProfile profileData={profileData} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="my_garage">
                                  <MyGarage updateUserVehicle={updateUserVehicle} profileData={profileData} />
                                </Tab.Pane>

                                <Tab.Pane eventKey="second">
                                  <div className="card mb-4 px-2">
                                    <div className="card-body">
                                      <div className="row gallery-section pt-0">

                                        {/* <div className="row">
                                          <div className="col-sm-3">
                                            <p className="mb-0 text-danger fw-bolder">Order Details</p>
                                          </div>
                                          <div className="col-sm-1">
                                            <p className="text-danger">:</p>
                                          </div>
                                          <div className="col-sm-8">
                                            <p className="mb-0 fw-bolder text-dark">Insurance Claim</p>

                                            <div className="horizontal-timeline mt-3">
                                              <ul className="list-inline items d-flex justify-content-between">
                                                <li className="list-inline-item items-list">
                                                  <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                    Pick-up
                                                  </p>
                                                </li>
                                                <li className="list-inline-item items-list">
                                                  <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                    At workshop
                                                  </p>
                                                </li>
                                                <li className="list-inline-item items-list">
                                                  <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                    Services time
                                                  </p>
                                                </li>
                                                <li className="list-inline-item items-list text-end">
                                                  <p style={{ marginRight: "-8px" }}>Delivered</p>
                                                </li>
                                              </ul>
                                            </div>

                                            <p className="mb-0 fw-bolder text-dark">Retail</p>

                                            <div className="horizontal-timeline mt-3">
                                              <ul className="list-inline items d-flex justify-content-between">
                                                <li className="list-inline-item items-list">
                                                  <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                    Pick-up
                                                  </p>
                                                </li>
                                                <li className="list-inline-item items-list">
                                                  <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                    At workshop
                                                  </p>
                                                </li>
                                                <li className="list-inline-item items-list">
                                                  <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                    Services time
                                                  </p>
                                                </li>
                                                <li className="list-inline-item items-list text-end">
                                                  <p style={{ marginRight: "-8px" }}>Delivered</p>
                                                </li>
                                              </ul>
                                            </div>

                                          </div>
                                        </div> */}

                                        <div className="mixitup-gallery mx-auto">
                                          <div className="filters clearfix">
                                            <Tabs
                                              defaultActiveKey="all"
                                              id="justify-tab-example"
                                              className="mb-3 mx-0 filter-tabs filter-btns text-center clearfix"
                                              justify
                                            >

                                              <Tab eventKey="all" title="All" className="filter">
                                                <Table bordered responsive hover mb-0 pt-4 variant="light">
                                                  <tbody>
                                                    <tr>
                                                      <td>Order Date</td>
                                                      <td>23994383489390293028390</td>
                                                      <td>Services </td>
                                                      <td>35465767887998</td>
                                                    </tr>

                                                    <tr>
                                                      <td>Car Details</td>
                                                      <td>Honda
                                                          City-Petrol
                                                          Petrol
                                                      </td>
                                                      <td>Car Reg. No.</td>
                                                      <td>KA50P9118</td>
                                                    </tr>

                                                    <tr colspan="4">
                                                      <td>Order Status</td>
                                                      {/* <td >National Insurance Co. Ltd.</td> */}
                                                      <td colspan="4">
                                                        <div className="horizontal-timeline mt-3">
                                                          <ul className="list-inline items d-flex justify-content-between">
                                                            <li className="list-inline-item items-list">
                                                              <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                                Pick-up
                                                              </p>
                                                            </li>
                                                            <li className="list-inline-item items-list">
                                                              <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                                At workshop
                                                              </p>
                                                            </li>
                                                            <li className="list-inline-item items-list">
                                                              <p className="py-1 px-2 rounded text-white" style={{ backgroundColor: "#f37a27" }}>
                                                                Services time
                                                              </p>
                                                            </li>
                                                            <li className="list-inline-item items-list text-end">
                                                              <p style={{ marginRight: "-8px" }}>Delivered</p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </td>
                                                    </tr>

                                                    <tr colspan="4">
                                                      <td colspan="4"><b>Order Details</b></td>
                                                    </tr>

                                                    <tr>
                                                      <td>pickup time</td>
                                                      <td>24 feb 2024</td>
                                                      <td>Workshop Details</td>
                                                      <td>National Insurance Co. Ltd.</td>
                                                    </tr>

                                                    <tr>
                                                      <td>Current Status</td>
                                                      <td>xyz</td>
                                                      <td>Job card download</td>
                                                      <td>PDF</td>
                                                    </tr>
                                                    
                                                  </tbody>
                                                </Table>
                                              </Tab>

                                              <Tab eventKey="Car-service" title="Car Service">
                                                <div className="row pt-3">
                                                  {/* Exterior tab */}
                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p> test 4</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test 2</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test 3</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Tab>

                                              <Tab eventKey="dent-paint" title="Dent & Paint">
                                                <div className="row pt-3">
                                                  {/* interior tab */}
                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p> test 4</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test 3</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Tab>

                                              <Tab eventKey="mechanical-repair" title="Mechanical Repair">
                                                <div className="row pt-3">
                                                  {/* deep-cleaning tab */}
                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test 2</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test 3</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Tab>

                                              <Tab eventKey="add-on" title="Add on">
                                                <div className="row pt-3">
                                                  {/* waxing tab */}
                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test ALl</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p> test 4</p>
                                                    </div>
                                                  </div>

                                                  <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                                                    <div className="inner-box">
                                                      <p>Test 3</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Tab>

                                            </Tabs>
                                          </div>
                                        
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="four">
                                  <div className="card mb-4 px-2">
                                    <div className="card-body">

                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">User Wallet</p>
                                        </div>
                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>
                                        <div className="col-sm-8">
                                          <p className="text-muted mb-0">Johnatan Smith</p>
                                        </div>
                                      </div>
                                      
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">coupon codes</p>
                                        </div>
                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>
                                        <div className="col-sm-8">
                                          <p className="text-muted mb-0">example@example.com</p>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="five">
                                  <div className="card mb-4 px-2">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-12">
                                          <p className="mb-0 fw-bolder text-center">Coming soon</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="six">
                                  <div className="card mb-4 px-2">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">Review </p>
                                        </div>

                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>

                                        <div className="col-sm-8">
                                          <form action="">
                                            <textarea
                                              name=""
                                              id=""
                                              cols="30"
                                              rows="10"
                                              className="form-control border-bottom border-danger border-0 bg-danger bg-opacity-10"
                                              placeholder="Review...."
                                            ></textarea>

                                            <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                                              <button className="theme-btn btn-style-five" type="submit" name="submit-form">
                                                <span className="txt">Submit</span>
                                              </button>
                                            </div>
                                          </form>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="seven">
                                  <div className="card mb-4 px-2">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">Notification </p>
                                        </div>
                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>
                                        <div className="col-sm-8 appointment-form">
                                          <div className="form-group col-sm-12">
                                            <div className="radio-box">
                                              <input type="radio" name="notification" value="ON" id="ON" />
                                              <Form.Label className="bg-transparent" for="ON">
                                                ON
                                              </Form.Label>
                                            </div>
                                            <div className="radio-box bg-transparent">
                                              <input type="radio" name="notification" value="OFF" id="OFF" />
                                              <Form.Label className="bg-transparent" for="OFF">
                                                OFF
                                              </Form.Label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">Account Delete </p>
                                        </div>
                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>
                                        <div className="col-sm-8">
                                          <p className="text-muted mb-0">
                                            <a href="/user-account-deletion" className="lightbox-image theme-btn btn-style-four btn-sm">
                                              <span className="txt">Delete</span>
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="eight">
                                  <div className="card mb-4 px-2">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">Support </p>
                                        </div>
                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>
                                        <div className="col-sm-8">
                                          <p className="text-muted mb-0">
                                            Email: support@vehiclecare.in <br />
                                            Phone: 9911-020-209 , 8010-90-0700
                                          </p>
                                        </div>
                                      </div>
                                      <br />
                                      
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <p className="mb-0 text-danger fw-bolder">Call Back </p>
                                        </div>
                                        <div className="col-sm-1">
                                          <p className="text-danger">:</p>
                                        </div>
                                        <div className="col-sm-8">
                                          <p className="text-muted mb-0">
                                            <a href="tel:9911-020-209" className="lightbox-image theme-btn btn-style-four btn-sm">
                                              <span className="txt">Call Back</span>
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="insurance_claim">
                                  <div className="card">
                                    <div className="card-body">
                                      <Table bordered responsive hover mb-0 variant="light">
                                        <tbody>
                                          <tr>
                                            <td>Policy No.</td>
                                            <td>23994383489390293028390</td>
                                            <td>Claim No.</td>
                                            <td>35465767887998</td>
                                          </tr>

                                          <tr>
                                            <td>Car Details</td>
                                            <td>Honda
                                                City-Petrol
                                                Petrol
                                            </td>
                                            <td>Car Reg. No.</td>
                                            <td>KA50P9118</td>
                                          </tr>

                                          <tr>
                                            <td>Insurance Company</td>
                                            <td>National Insurance Co. Ltd.</td>
                                            <td>Insurance Type</td>
                                            <td>Comprehensive</td>
                                          </tr>
                                          
                                        </tbody>
                                      </Table>
                                    </div>
                                    
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Row>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
        
      </Row>

      {/* Our App */}
      {/* <div
        className="testimonial-section-one bg-size pb-5"
        style={{
          backgroundImage: `url(${Appbg})`,
          backgroundSize: `cover`,
          backgroundColor: `#f5f1ee`,
        }}
      >
        <OurApp />
      </div> */}
      {!bottomSheet && <BottomTabs route_name={'profile'}  user={is_user_logged_in} />}
      <Footer bottomTabs={true}  />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createServiceQuote(params, dispatch),
  getUserProfile: (params) => UserAction.getUserProfile(params, dispatch),
  updateUserVehicle: (params) => UserAction.updateUserVehicle(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
